import * as React from 'react';
import '../styles/chat-list.css';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  CssBaseline,
  IconButton
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 240;
export const ChatList = ({ chats, activeChat, setActiveChat, }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false); // State for Drawer collapse
  const [mobileOpen, setMobileOpen] = React.useState(false); // Drawer state for mobile

  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed); // Toggle collapse state
  };

  const handleChatSelect = (chat: any) => {
    setActiveChat(chat);
    setMobileOpen(false); // Close the drawer when a chat is selected (for mobile view)
  };

  const drawer = (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
       {/* Collapse Button */}
       <div className="drawer-header">
         <IconButton onClick={handleCollapseToggle} className="drawer-header-icon">
           {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
         </IconButton>
       </div>
      <List className="chat-list scrollable">
        {chats.map((chat) => (
          <ListItem
            component="div"
            key={chat.id}
            onClick={() => handleChatSelect(chat)}
            className={`chat-item ${chat.id === activeChat?.id ? 'active' : ''}`}
            role="button" // Optional: for accessibility purposes
          >
            <IconButton>
              <i className="chat-icon" /> {/* Insert your icon logic here */}
            </IconButton>
            {!isCollapsed && <ListItemText primary={chat.name} />}
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box
      sx={{ width: { sm: isCollapsed ? 60 : drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="chat list"
    >
      <CssBaseline />
      {/* Temporary Drawer for Mobile */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleMobileDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better performance on mobile
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isCollapsed ? 60 : drawerWidth,
            marginTop: '64px', // Ensures the Drawer is below the AppBar on mobile
            transition: 'width 0.3s ease-in-out',
          },
        }}
      >
        {drawer}
      </Drawer>

      {/* Permanent Drawer for Desktop */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: isCollapsed ? 60 : drawerWidth,
            marginTop: '64px', // Pushes Drawer down below the AppBar on desktop
            transition: 'width 0.3s ease-in-out',
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
