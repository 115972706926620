import React from 'react';
import RollingMascot from './maskot';
import '../styles/comingsoonpage.css'



export const ComingSoonPage: React.FC = () => {
  return (
    <div className="coming-soon-page">
      <h1>Work in Progress ...</h1>
      <RollingMascot />
      <h2>Stay tuned, coming soon</h2>
      <footer>
        <p>© 2024 SLAN AI Team Builder. All rights reserved.</p>
      </footer>
    </div>
  );
};
