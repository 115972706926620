import React from 'react';
import './App.css';
import { AppFrame } from './ui-components/app-frame';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './utils/protectedRoute';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ChatsPage } from './chat/chat';
import { HomePage } from './pages/homepage';
import { ComingSoonPage } from './pages/comingsoonpage';
import { LogIn } from "./pages/login"
import { NewPassword } from "./pages/newpassword"
import { blue, darkBlue, yellow } from './utils/color-palette';
import { AuthProvider } from './context/AuthContext';

/**
 * Create Material-UI theme
 */
const theme = createTheme({
  palette: {
    primary: {
      main: darkBlue,
    },
    secondary: {
      main: yellow,
    },
  },
});

/**
 * Main application component
 * @returns {JSX.Element} The root application component
 */
function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <React.Fragment>
            <AppFrame />
            <div className='app-container'>
              <Routes>
                <Route path="/" element={<HomePage/>} />
                <Route path="/explore" element={<ComingSoonPage/>} />
                <Route path="/about" element={<ComingSoonPage/>} />
                <Route path="/logout" element={<ComingSoonPage/>} />
                <Route path="/forgot-password" element={<ComingSoonPage/>} />
                <Route path="/create-account" element={<ComingSoonPage/>} />
                <Route path="/login" element={<LogIn/>} />
                <Route path="/new-password" element={<NewPassword/>} />
                <Route path="/profile" element={<ProtectedRoute><ComingSoonPage/></ProtectedRoute>}/>
                <Route path="/account" element={<ProtectedRoute><ComingSoonPage/></ProtectedRoute>}/>
                <Route path="/dashboard" element={<ProtectedRoute><ComingSoonPage/></ProtectedRoute>}/>
                <Route path="/chat" element={<ProtectedRoute><ChatsPage/></ProtectedRoute>}/>
              </Routes>
            </div>
          </React.Fragment>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;