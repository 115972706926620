import * as React from 'react';
import '../styles/chat.css';


export const ChatBar = ({sendMessage}) => {
    const [input, setInput] = React.useState('');

    const onSendButtonClick = (e) => {
        e.preventDefault();
        sendMessage(input);
        setInput('');
    }
    return <form className="message-form" onSubmit={onSendButtonClick}>
        <input
            type="text"
            placeholder="Type a message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="message-input"
        />
        <button type="submit" className="send-button">Send</button>
    </form>
}