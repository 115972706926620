import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticateUser } from '../api/auth';
import { useAuth } from '../context/AuthContext';
import { AuthError } from '../types/auth';
import CircularProgress from '@mui/material/CircularProgress';
import '../styles/login.css';

/**
 * Login component that handles user authentication
 * @returns {JSX.Element} The login component
 */
export const LogIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { checkSession, isAuthenticated } = useAuth();

  useEffect(() => {
    const checkExistingSession = async () => {
      // Add a small delay to ensure any logout operations are completed
      await new Promise(resolve => setTimeout(resolve, 100));
      const hasValidSession = await checkSession();
      if (hasValidSession) {
        navigate('/chat');
      }
    };
    checkExistingSession();
  }, [checkSession, navigate]);

  useEffect(() => {
    // Redirect if user becomes authenticated
    if (isAuthenticated) {
      navigate('/chat');
    }
  }, [isAuthenticated, navigate]);

  /**
   * Handles the error message based on the error code
   * @param {AuthError} err - The authentication error
   * @returns {string} The formatted error message
   */
  const getErrorMessage = (err: AuthError): string => {
    switch (err.code) {
      case 'UserNotFoundException':
        return 'No account found with this email address.';
      case 'NotAuthorizedException':
        return 'Incorrect email or password.';
      case 'UserNotConfirmedException':
        return 'Please verify your email address before logging in.';
      case 'PasswordResetRequiredException':
        return 'Password reset is required. Please check your email.';
      default:
        return 'An error occurred during login. Please try again.';
    }
  };

  /**
   * Handles form submission for login
   * @param {React.FormEvent<HTMLFormElement>} e - The form event
   */
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      authenticateUser(
        email,
        password,
        async (result) => {
          try {
            const accessToken = result.getAccessToken().getJwtToken();
            const idToken = result.getIdToken().getJwtToken();
            
            // Store auth data
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('idToken', idToken);
            localStorage.setItem('userEmail', email);

            // Verify session after storing tokens
            await checkSession();
            setLoading(false);
            navigate('/chat');
          } catch (err) {
            setError('Error processing authentication tokens');
            setLoading(false);
          }
        },
        (err: AuthError) => {
          setError(getErrorMessage(err));
          setLoading(false);
        },
        (userAttributes, requiredAttributes) => {
          navigate('/new-password', { 
            state: { 
              email,
              password,
              userAttributes,
              requiredAttributes
            },
            replace: true
          });
        }
      );
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="page-wrapper-login">
      <div className="container-login">
        <form className="form-login" onSubmit={handleSubmit}>
          <h2 className="title-login">Sign In to SLAN</h2>

          {error && (
            <div className="error-message-login" role="alert">
              {error}
            </div>
          )}

          <div className="input-group-login">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              disabled={loading}
              required
              className="input-login"
              aria-label="Email"
            />
          </div>

          <div className="input-group-login">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              required
              className="input-login"
              aria-label="Password"
              // minLength={8}
            />
          </div>

          <button
            type="submit"
            className="button-login"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Sign In'
            )}
          </button>

          <div className="support-links-login">
            <a
              href="/forgot-password"
              className="support-link"
              tabIndex={loading ? -1 : 0}
            >
              Forgot Password?
            </a>
            <a
              href="/create-account"
              className="support-link-login"
              tabIndex={loading ? -1 : 0}
            >
              Create Account
            </a>
          </div>
        </form>
      </div>

      <div className="image-container-login">
        <img src="/mascot-login.png" alt="SLAN Mascot" className="mascot-image" />
      </div>
    </div>
  );
};