import React, { useEffect, useRef } from 'react';
import '../styles/maskot.css';

interface BubbleProps {
  size: number;
  top: number;
  left: number;
  animationDuration: number;
}

const Bubble: React.FC<BubbleProps> = ({ size, top, left, animationDuration }) => (
  <div
    className="bubble"
    style={{
      width: `${size}px`,
      height: `${size}px`,
      top: `${top}%`,
      left: `${left}%`,
      animation: `float ${animationDuration}s ease-in-out infinite`,
    }}
  />
);

const Mascot: React.FC = () => {
  const mascotRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const mascot = mascotRef.current;
    if (!mascot) return;

    const createBubble = () => {
      const size = Math.random() * 10 + 5;
      const top = Math.random() * 100;
      const left = Math.random() * 100;
      const animationDuration = Math.random() * 3 + 2;

      const bubble = document.createElement('div');
      bubble.className = 'bubble';
      bubble.style.width = `${size}px`;
      bubble.style.height = `${size}px`;
      bubble.style.top = `${top}%`;
      bubble.style.left = `${left}%`;
      bubble.style.animation = `float ${animationDuration}s ease-in-out infinite`;

      mascot.appendChild(bubble);

      setTimeout(() => {
        mascot.removeChild(bubble);
      }, animationDuration * 1000);
    };

    const interval = setInterval(createBubble, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="mascot" ref={mascotRef}>
      <div className="mascot-body">
        <div className="mascot-eye mascot-eye-left">
          <div className="mascot-eye-highlight" />
        </div>
        <div className="mascot-eye mascot-eye-right">
          <div className="mascot-eye-highlight" />
        </div>
        <div className="mascot-smile" />
        <div className="mascot-cheek mascot-cheek-left" />
        <div className="mascot-cheek mascot-cheek-right" />
      </div>
      <Bubble size={8} top={-10} left={10} animationDuration={3} />
      <Bubble size={6} top={-5} left={80} animationDuration={4} />
      <Bubble size={4} top={90} left={20} animationDuration={5} />
    </div>
  );
};

const SimpleMascot = () => (
  <div className="simple-mascot">
    <div className="mascot-body">
      <div className="mascot-eye mascot-eye-left">
        <div className="mascot-eye-highlight" />
      </div>
      <div className="mascot-eye mascot-eye-right">
        <div className="mascot-eye-highlight" />
      </div>
      <div className="mascot-smile" />
      <div className="mascot-cheek mascot-cheek-left" />
      <div className="mascot-cheek mascot-cheek-right" />
    </div>
  </div>
);
const RollingMascot = () => (
  <div className="rolling-mascot">
    <div className="mascot-body">
      <div className="mascot-eye mascot-eye-left">
        <div className="mascot-eye-highlight" />
      </div>
      <div className="mascot-eye mascot-eye-right">
        <div className="mascot-eye-highlight" />
      </div>
      <div className="mascot-smile" />
      <div className="mascot-cheek mascot-cheek-left" />
      <div className="mascot-cheek mascot-cheek-right" />
    </div>
  </div>
);
export default RollingMascot;
