import * as React from 'react';
import '../styles/message.css';
import { getCurrentUser } from '../api/auth';
import ReactMarkdown from 'react-markdown';

interface MessageProps {
    content: string;
    sender: string;
    timeStamp: string;
}

const processContent = (content: string): string => {
    // Replace \n with actual newlines
    return content.replace(/\\n/g, '\n');
};

const IncomingMessage = ({ content, timeStamp, sender }: MessageProps) => {
    const processedContent = processContent(content);
    return (
        <div className="message incoming">
            <div className="message-content">
                <p className="message-sender">{sender}</p>
                <div className="message-text">
                <ReactMarkdown>{processedContent}</ReactMarkdown>
                </div>
                <span className="message-timestamp">{timeStamp}</span>
            </div>
        </div>
    );
};

const OutgoingMessage = ({ content, timeStamp }: Omit<MessageProps, 'sender'>) => {
    return (
        <div className="message outgoing">
            <div className="message-content">
                <div className="message-text">
                    <ReactMarkdown>{content}</ReactMarkdown>
                </div>
                <span className="message-timestamp">{timeStamp}</span>
            </div>
        </div>
    );
};

export const Message = ({ content, sender, timeStamp }: MessageProps) => {
    const user = getCurrentUser();

    return user === sender ? 
        <OutgoingMessage content={content} timeStamp={timeStamp} /> :
        <IncomingMessage content={content} timeStamp={timeStamp} sender={sender} />
};