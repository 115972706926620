import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { userPool } from '../config/cognitoConfig';
import { User } from '../types/auth';

// Store the cognito user instance globally to maintain session
let currentCognitoUser: CognitoUser | null = null;

export const authenticateUser = (
  email: string,
  password: string,
  onSuccess: (result: any) => void,
  onFailure: (error: any) => void,
  onNewPasswordRequired: (userAttributes: any, requiredAttributes: any) => void
) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  const userData = {
    Username: email,
    Pool: userPool,
  };

  currentCognitoUser = new CognitoUser(userData);

  currentCognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: (result) => {
      onSuccess(result);
    },
    onFailure: (err) => {
      onFailure(err);
    },
    newPasswordRequired: (userAttributes, requiredAttributes) => {
      onNewPasswordRequired(userAttributes, requiredAttributes);
    },
  });
};

export const completeNewPasswordChallenge = (
    newPassword: string,
    onSuccess: () => void,
    onFailure: (error: any) => void,
    userAttributes?: User
  ) => {
    if (!currentCognitoUser) {
      onFailure(new Error('No authenticated user found'));
      return;
    }
  
    const cognitoUserAttributes = userAttributes
      ? {
          birthdate: userAttributes.birthdate,
          gender: userAttributes.gender,
          given_name: userAttributes.given_name,
          family_name: userAttributes.family_name,
        }
      : {};
  
    currentCognitoUser.completeNewPasswordChallenge(
      newPassword,
      cognitoUserAttributes,
      {
        onSuccess: () => {
          onSuccess();
        },
        onFailure: (err) => {
          onFailure(err);
        },
      }
    );
  };

export const getCurrentUser = (): string | null => {
  const user = userPool.getCurrentUser();
  return user ? user.getUsername() : null;
};

export const getCurrentSession = async (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser();
    if (!user) {
      reject('No user found');
      return;
    }

    user.getSession((err: Error | null, session: any) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(session);
    });
  });
};