import React from 'react';
import { FaPlay, FaSmile, FaGlobe } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../styles/homepage.css';

const HeroSection = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/chat'); // Redirects to /chat page
  };

  return (
    <section className="hero">
      <div className="hero-content">
        <h1 className="hero-title-large">SLAN <span className="highlight"> :)</span></h1>
        <h1 className="hero-title">BUILD YOUR <span className="highlight">AI TEAM</span></h1>
        <p className="hero-subtitle">
          The network of AI assistants that works for you—helping you get answers faster, automate routine tasks, and reclaim precious time for what matters most.
        </p>
        <button className="cta-button" onClick={handleGetStarted}>
          <FaPlay className="icon" /> Get Started Now
        </button>
        <p className="cta-subtext">
          Join thousands of users building their perfect AI team today.
        </p>
      </div>
      <div className="abstract-visual">
        <div className="visual-placeholder"></div>
      </div>
    </section>
  );
};

const Footer = () => {
  const navigate = useNavigate();

  const handleContact = () => {
    navigate('/about'); // Redirects to /about page
  };

  return (
    <footer className="footer">
      <button className="contact-button" onClick={handleContact}>CONTACT</button>
      <div className="certified-badge">
        <FaGlobe /> Powered by SLAN’s Intelligent Collaboration Network
      </div>
      <FaSmile className="smile-icon" />
    </footer>
  );
};

export const HomePage: React.FC = () => {
  return (
    <div className="container">
      <HeroSection />
      <Footer />
    </div>
  );
};
