import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../context/AuthContext';
import '../styles/appbar.css';

/**
 * Interface for navigation items
 */
interface NavigationItem {
  label: string;
  link: string;
}

// Navigation items for different authentication states
const publicPages: NavigationItem[] = [
  { label: 'Home', link: '/' },
  { label: 'Explore', link: '/explore' },
  { label: 'About', link: '/about' },
];

const authenticatedPages: NavigationItem[] = [
  { label: 'Home', link: '/' },
  { label: 'Explore', link: '/explore' },
  { label: 'Chat', link: '/chat' },
  { label: 'About', link: '/about' },
];

const authenticatedSettings: NavigationItem[] = [
  { label: 'Profile', link: '/profile' },
  { label: 'Account', link: '/account' },
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Logout', link: '/logout' },
];

const publicSettings: NavigationItem[] = [
  { label: 'Login', link: '/login' },
  { label: 'Create Account', link: '/create-account' },
];


/**
 * AppFrame component that serves as the main navigation header
 * @returns {JSX.Element} The AppFrame component
 */
export const AppFrame: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, user, logout } = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  // Use appropriate pages based on auth status
  const pages = isAuthenticated ? authenticatedPages : publicPages;
  const settings = isAuthenticated ? authenticatedSettings : publicSettings;

  /**
   * Gets user's initials for avatar display
   * @returns {string} User's initials or fallback character
   */
  const getInitials = (): string => {
    if (user?.given_name && user?.family_name) {
      return `${user.given_name[0]}${user.family_name[0]}`.toUpperCase();
    }
    return user?.email?.[0]?.toUpperCase() || '?';
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /**
   * Handles menu item clicks with navigation
   * @param {string} link - The destination path
   */
  const handleMenuClick = async (link: string) => {
    handleCloseUserMenu();
    if (link === '/logout') {
      await logout();
      return;
    }
    handleNavigation(link);
  };

  /**
   * Checks if the current page matches the navigation item
   * @param {string} link - The path to check
   * @returns {boolean} Whether the path is active
   */
  const isActivePage = (link: string): boolean => {
    return location.pathname === link;
  };
  
  const handleNavigation = (link: string) => {
    if (isActivePage(link)) {
      return; // Don't navigate to the same page
    }
    navigate(link);
  };
  

  // Show loading indicator while authentication state is being determined
  if (isLoading) {
    return (
      <AppBar position="fixed" sx={{ backgroundColor: 'var(--color-background-secondary)' }}>
        <Toolbar sx={{ justifyContent: 'center' }}>
          <CircularProgress size={24} color="inherit" />
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        backgroundColor: 'var(--color-background-secondary)', 
        zIndex: (theme) => theme.zIndex.drawer + 1, 
        boxShadow: 'none', 
        elevation: 0, 
        top: 0,
        borderBottom: '1px solid var(--color-border)'
      }}
    >
      <Toolbar disableGutters sx={{ px: { xs: 2, md: 3 } }}>
        {/* Logo - Desktop */}
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          color="var(--color-text-primary)"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.1rem',
            textDecoration: 'none',
          }}
        >
          SLAN <span className="smile-highlight"> :) </span>
        </Typography>

        {/* Mobile Navigation Menu */}
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="navigation menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            sx={{ color: 'var(--color-text-primary)' }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            {pages.map((page) => (
              <MenuItem 
                key={page.label} 
                onClick={() => {
                  handleCloseNavMenu();
                  handleNavigation(page.link);
                }}
                selected={isActivePage(page.link)}
              >
                <Typography textAlign="center">{page.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        {/* Logo - Mobile */}
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: 'flex', md: 'none' },
            flexGrow: 1,
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'var(--color-text-primary)',
            textDecoration: 'none',
          }}
        >
          SLAN
        </Typography>

        {/* Desktop Navigation Menu */}
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
          {pages.map((page) => (
            <Button
              key={page.label}
              onClick={() => handleNavigation(page.link)}
              sx={{
                my: 2,
                mx: 1,
                color: 'var(--color-text-primary)',
                display: 'block',
                fontWeight: isActivePage(page.link) ? 'bold' : 'normal',
                position: 'relative',
                '&::after': isActivePage(page.link) ? {
                  content: '""',
                  position: 'absolute',
                  bottom: '10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: '5px',
                  height: '5px',
                  borderRadius: '50%',
                  backgroundColor: 'var(--color-primary)',
                } : {}
              }}
            >
              {page.label}
            </Button>
          ))}
        </Box>

        {/* User Menu */}
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title={isAuthenticated ? "Open settings" : "Account options"}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar 
                sx={{ 
                  bgcolor: isAuthenticated ? 'var(--color-primary)' : 'var(--color-background-tertiary)',
                  color: 'var(--color-text-inverse)',
                }}
              >
                {isAuthenticated ? getInitials() : '?'}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem 
                key={setting.label} 
                onClick={() => handleMenuClick(setting.link)}
                sx={{
                  color: setting.label === 'Logout' ? 'var(--color-error)' : 'inherit',
                }}
              >
                <Typography textAlign="center">{setting.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};