import axios from 'axios';
const API_BASE_URL = 'http://127.0.0.1:8000'
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  },
  
});

interface Participant {
  assistant_name: string;
  assistant_id: string; 
}

interface Message {
  sender: string;
  content: string;
  timestamp: number; //utc seconds
  id: string;
}

interface MessageRequest {
  sender: string;
  content: string;
}

interface Chat {
  chatId: string;
  chatName: string;
  lastTimeUpdated: number; //utc seconds;
  participants: Participant[];
  userId: string;
}

const dummyMessage = [{"timeStamp":1725816628,"attachments":[],"id":"1","sender":"Leana","content":"Hey World"}]

const dummyData = [
    {
      id: 1,
      name: 'General Chat',
      messages: [
        { id: 1,  sender: 'Alice', content: 'Hi everyone!', timestamp: '10:00 AM' },
       dummyMessage[0],
      ]
    },
    {
      id: 2,
      name: 'Tech Talk',
      messages: [
        { id: 1,  sender: 'Bob', content: 'Has anyone tried the new React?', timestamp: '10:30 AM' }
      ]
    },
    {
      id: 3,
      name: 'Random',
      messages: []
    }
  ]

export const getChatMessages = async (id) => {
  try {
    const response = await apiClient.get<Message[]>(`${API_BASE_URL}/chats/${id}/messages`);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}


export const getUserChats = async (userId="Leana") => {
  // try {
  //   const response = await apiClient.get<Chat[]>(`/chats/${userId}`);
  //   return response.data;
  // } catch (error) {
  //   console.error('Error fetching items:', error);
  //   throw error;
  // }
  return dummyData;
}

export const sendMessage = async (chatId: string, message:MessageRequest)=>{

  try{
    const response = await apiClient.post<Message>(`${API_BASE_URL}/chats/${chatId}`, message);
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
}