import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authenticateUser, completeNewPasswordChallenge } from '../api/auth';
import { 
  User, 
  AuthError, 
  NewPasswordChallengeParams 
} from '../types/auth';
import '../styles/newpassword.css';

/**
 * Component for handling new password setup and user attributes collection
 * This is shown when a user needs to change their password on first login
 * or after admin password reset
 */
export const NewPassword: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as NewPasswordChallengeParams;
  
  // Form state
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [attributes, setAttributes] = useState<User>({
    email: state?.email || '',
    birthdate: '',
    gender: '',
    given_name: '',
    family_name: ''
  });
  
  // UI state
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  
  useEffect(() => {
    if (!state?.email || !state?.password) {
      setError("Missing login information. Please try logging in again.");
      setTimeout(() => {
        navigate('/login');
      }, 3000);
      return;
    }

    // Re-authenticate to ensure the session is valid
    authenticateUser(
      state.email,
      state.password,
      () => {
        navigate('/login');
      },
      (err: AuthError) => {
        setError("Authentication failed. Please try logging in again.");
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      },
      () => {
        // Ready for new password challenge
      }
    );
  }, [state, navigate]);

  /**
   * Validates password against security requirements
   * @param password - The password to validate
   * @returns Error message if invalid, null if valid
   */
  const validatePassword = (password: string): string | null => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long";
    }
    if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one uppercase letter";
    }
    if (!/[a-z]/.test(password)) {
      return "Password must contain at least one lowercase letter";
    }
    if (!/[0-9]/.test(password)) {
      return "Password must contain at least one number";
    }
    if (!/[^A-Za-z0-9]/.test(password)) {
      return "Password must contain at least one special character";
    }
    return null;
  };

  /**
   * Validates required user attributes
   * @returns Error message if invalid, null if valid
   */
  const validateAttributes = (): string | null => {
    const { birthdate, gender, given_name, family_name } = attributes;
    
    if (!birthdate) return "Birth date is required";
    if (!gender) return "Gender is required";
    if (!given_name) return "First name is required";
    if (!family_name) return "Last name is required";

    const birthdateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!birthdateRegex.test(birthdate)) {
      return "Birth date must be in YYYY-MM-DD format";
    }

    return null;
  };

  /**
   * Handles form submission for password change and attribute update
   * @param e - Form submission event
   */
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }

    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      setError(passwordError);
      setLoading(false);
      return;
    }

    const attributesError = validateAttributes();
    if (attributesError) {
      setError(attributesError);
      setLoading(false);
      return;
    }

    try {
      completeNewPasswordChallenge(
        newPassword,
        () => {
          localStorage.setItem('userAttributes', JSON.stringify(attributes));
          setSuccess("Password changed successfully!");
          setTimeout(() => navigate('/login'), 2000);
        },
        (err: AuthError) => {
          setError(err.message || "An error occurred while changing the password");
          setLoading(false);
        },
        attributes,
      );
    } catch (err: unknown) {
      const error = err as AuthError;
      setError(error.message || "An error occurred while changing the password");
      setLoading(false);
    }
  };

  /**
   * Handles changes to user attribute inputs
   * @param e - Input change event
   */
  const handleAttributeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setAttributes(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="page-wrapper-newpassword">
      <div className="container-newpassword">
        <form className="form-newpassword" onSubmit={handleSubmit}>
          <h2 className="title-newpassword">Set New Password</h2>
  
          {error && <div className="error-message-newpassword" role="alert">{error}</div>}
          {success && <div className="success-message-newpassword" role="alert">{success}</div>}
  
          {/* Account Information Section */}
          <div className="account-info-section-newpassword">
            <h3 className="section-heading-newpassword">Account Information</h3>
            <div className="input-group-newpassword">
              <div className="email">
                <label className="input-label-newpassword">Email Address</label>
                <input
                  type="email"
                  value={state?.email || ''}
                  readOnly
                  className="input readonly"
                  aria-label="Email Address"
                />
                <div className="readonly-note-newpassword">Email cannot be changed</div>
              </div>
            </div>
          </div>
  
  
          {/* Account Security Section */}
          <div className="account-security-section-newpassword">
            <h3 className="section-heading-newpassword">Account Security</h3>
                {/* Password Requirements Section */}
          <div className="password-requirements-section-newpassword">
            <p>Password must contain:</p>
            <ul>
              <li>At least 8 characters</li>
              <li>One uppercase letter</li>
              <li>One lowercase letter</li>
              <li>One number</li>
              <li>One special character</li>
            </ul>
          </div>
            <div className="input-group-newpassword">
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                disabled={loading}
                required
                className="input"
                aria-label="New Password"
              />
            </div>
  
            <div className="input-group-newpassword">
              <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                disabled={loading}
                required
                className="input"
                aria-label="Confirm New Password"
              />
            </div>
          </div>
  
          {/* Personal Information Section */}
          <div className="personal-info-section-newpassword">
            <h3 className="section-heading">Personal Information</h3>
            
            {/* First Name and Last Name on one line */}
            <div className="inline-form-section-newpassword">
              <div className="input-group-newpassword half-width">
                <input
                  type="text"
                  placeholder="First Name"
                  name="given_name"
                  value={attributes.given_name}
                  onChange={handleAttributeChange}
                  disabled={loading}
                  required
                  className="input"
                  aria-label="First Name"
                />
              </div>
  
              <div className="input-group-newpassword half-width">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="family_name"
                  value={attributes.family_name}
                  onChange={handleAttributeChange}
                  disabled={loading}
                  required
                  className="input"
                  aria-label="Last Name"
                />
              </div>
            </div>
  
            {/* Birth Date and Gender on one line */}
            <div className="inline-form-section-newpassword">
              <div className="input-group-newpassword  half-width">
                <input
                  type="date"
                  placeholder="Birth Date"
                  name="birthdate"
                  value={attributes.birthdate}
                  onChange={handleAttributeChange}
                  disabled={loading}
                  required
                  className="date-input-newpassword"
                  aria-label="Birth Date"
                />
              </div>
  
              <div className="input-group-newpassword half-width">
                <select
                  name="gender"
                  value={attributes.gender}
                  onChange={handleAttributeChange}
                  disabled={loading}
                  required
                  className="select-input-newpassword"
                  aria-label="Gender"
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                  <option value="prefer_not_to_say">Prefer not to say</option>
                </select>
              </div>
            </div>
          </div>
  
          {/* Submit Button */}
          <button 
            type="submit" 
            className="button-newpassword"
            disabled={loading}
          >
            {loading ? 'Changing Password...' : 'Change Password'}
          </button>
        </form>
      </div>
    </div>
  );
};