import * as React from 'react';
import '../styles/chat.css';
import '../styles/message.css';
import '../styles/chat-list.css';
import { getCurrentUser } from '../api/auth';
import { Message } from './message';
import { ChatBar } from './chat-bar';
import { getChatMessages, getUserChats, sendMessage } from '../api/chat';
import { ChatList } from '../chat/chats-list';
import {
    Typography,
    Box,
    Divider,
    CircularProgress,
} from '@mui/material';

// Chat component structure for better layout handling
const Chat = ({ chatId }) => {
    const [messages, setMessages] = React.useState(null);
    const [isNearBottom, setIsNearBottom] = React.useState(true); // New state to track scroll

    React.useEffect(() => {
        const fetchMessages = async () => {
            try {
                const result = await getChatMessages(chatId);
                setMessages(result);
            } catch (e) {
                console.log(e);
            }
        };
        fetchMessages();
    }, [chatId]);

    const currentUser = getCurrentUser();
    const messagesEndRef = React.useRef(null);

    const sendChatMessage = React.useCallback(async (message) => {
        if (message.trim() === '') return;

        const newMessage = {
            sender: currentUser,
            content: message,
            timeStamp: Date.now() / 1000,
        };

        // Optimistically add the message
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        try {
            const result = await sendMessage(chatId, newMessage);
            setMessages((prevMessages) => [...prevMessages.filter(m => m !== newMessage), result]);
        } catch (error) {
            console.log("Error sending message:", error);
            // Optionally, show a notification or revert UI changes
        }
    }, [currentUser, chatId]);

    const handleScroll = () => {
        if (messagesEndRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = messagesEndRef.current.parentNode;
            const isNearBottom = scrollHeight - scrollTop <= clientHeight + 50;
            setIsNearBottom(isNearBottom);
        }
    };

    React.useEffect(() => {
        if (isNearBottom && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, isNearBottom]);

    return (
        <div className="chat-container">
            <div className={`messages-container ${!messages ? 'loading' : ''}`} onScroll={handleScroll}>
                <div className="messages">
                    {messages && messages.length > 0 ? (
                        messages.map((msg, index) => (
                            <Message
                                key={index}
                                content={msg.content}
                                sender={msg.sender}
                                timeStamp={new Date(msg.timeStamp * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            />
                        ))
                    ) : (
                        <Typography variant="body1" color="textSecondary">
                            No messages yet. Start the conversation!
                        </Typography>
                    )}
                    <div ref={messagesEndRef} />
                </div>
            </div>
            <ChatBar sendMessage={sendChatMessage} />
        </div>
    );
};

export const ChatsPage = () => {
    const userId = 'Leana'; //HARD CODING THIS FOR NOW UNTIL AUTH WORKS
    const [chats, setChats] = React.useState(null);
    const [activeChat, setActiveChat] = React.useState(null);

    React.useEffect(() => {
        const getChats = async (userId) => {
            const result = await getUserChats(userId);
            setChats(result);
            if (result.length > 0) {
                setActiveChat(result[0]);
            }
        };
        getChats(userId).catch((e) => console.log(e));
    }, []);

    return chats ? (
        <React.Fragment>
            <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
                {/* Fix the width of the ChatList to prevent overlap */}
                <Box sx={{
                    width: '250px', /* Fixed width for the chat list */
                    backgroundColor: 'var(--color-background)', /* Ensure chat list is visually distinct */
                    overflowY: 'auto', /* Vertical scrolling in chat list */
                    height: '100vh', /* Full height for chat list */
                    flexShrink: 0, /* Prevent the chat list from shrinking */
                }}>
                    <ChatList
                        chats={chats}
                        activeChat={activeChat}
                        setActiveChat={setActiveChat}
                    />
                </Box>

                {/* Chat content section with flexGrow to take up remaining space */}
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    overflowY: 'auto', /* Enable vertical scrolling in the chat area */
                }}>
                    <Typography variant="h4" padding={'5px'} className="chat-header">
                        {activeChat?.name}
                    </Typography>
                    <Divider />
                    {activeChat ? <Chat chatId={activeChat?.id} /> : <CircularProgress />}
                </Box>
            </Box>
        </React.Fragment>
    ) : <CircularProgress />;
};
