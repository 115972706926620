import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser, getCurrentSession } from '../api/auth';
import { AuthState, AuthContextType } from '../types/auth';

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  isLoading: true,
  user: null,
  logout: async () => {},
  checkSession: async () => false,
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    isLoading: true,
    user: null,
  });
  const navigate = useNavigate();

  const checkSession = useCallback(async (): Promise<boolean> => {
    if (!authState.isLoading && authState.isAuthenticated) {
      return true; // Don't recheck if we're already authenticated
    }

    try {
      const accessToken = localStorage.getItem('accessToken');
      const idToken = localStorage.getItem('idToken');
      const userEmail = localStorage.getItem('userEmail');
      
      if (!accessToken || !idToken || !userEmail) {
        throw new Error('No tokens found');
      }

      const session = await getCurrentSession();
      const user = await getCurrentUser();
      
      if (session && user) {
        const userAttributes = localStorage.getItem('userAttributes');
        const parsedAttributes = userAttributes ? JSON.parse(userAttributes) : {};

        setAuthState({
          isAuthenticated: true,
          isLoading: false,
          user: {
            email: userEmail,
            ...parsedAttributes
          },
        });
        return true;
      }
      throw new Error('Invalid session');
    } catch (error) {
      // Clear auth data only if we had some to begin with
      if (localStorage.getItem('accessToken')) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('idToken');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userAttributes');
      }

      setAuthState({
        isAuthenticated: false,
        isLoading: false,
        user: null,
      });
      return false;
    }
  }, [authState.isLoading, authState.isAuthenticated]);

  const logout = useCallback(async (): Promise<void> => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userAttributes');

    setAuthState({
      isAuthenticated: false,
      isLoading: false,
      user: null,
    });

    await new Promise(resolve => setTimeout(resolve, 0));
    navigate('/login');
  }, [navigate]);

  // Initial session check
  useEffect(() => {
    checkSession();
  }, [checkSession]);

  const contextValue = React.useMemo(() => ({
    ...authState,
    logout,
    checkSession,
  }), [authState, logout, checkSession]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};